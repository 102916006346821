<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
import { formDate2 } from "@/assets/js/util.js";

const nowConf = ref([]);
const pastConf = ref([]);

const getConfs = async () => {
  try {
    const response = await axios.get("/api/conference/list");
    const conferences = response.data.contents;

    nowConf.value = conferences.filter((conf) => !conf.isEnd);
    pastConf.value = conferences.filter((conf) => conf.isEnd);
  } catch (err) {
    console.error(err);
  }
};

onMounted(getConfs);
</script>

<template>
  <div id="nowConf"></div>
  <div
    class="py-5"
    style="
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 54.9%,
        rgba(12, 51, 136, 1) 55%,
        rgba(12, 51, 136, 1) 100%
      );
    "
  >
    <h2 class="nanum-square font-bold text-center my-10 text-white">
      진행 중인 컨퍼런스
    </h2>
    <!-- loop="true" -->

    <div
      class="conf_main_swiper max-xl:max-w-[88vw] xl:max-w-[65vw] mx-auto"
      v-if="nowConf.length"
    >
      <swiper-container
        navigation="true"
        autoplay-delay="4000"
        slides-per-view="1"
        space-between="30"
        :breakpoints="{
          '768': {
            slidesPerView: 2,
            spaceBetween: 60,
          },
        }"
      >
        <swiper-slide v-for="conf in nowConf" :key="conf.idx">
          <div
            class="w-full aspect-[83/50] bg-[#F0F0F0] rounded-xl overflow-hidden shadow-lg"
          >
            <router-link
              :to="'/' + conf.idx"
              class="w-full h-full flex justify-center items-center"
            >
              <img
                v-if="conf.thumbnail"
                :src="'https://nodeblack.net/' + conf.thumbnail"
                class="w-full h-full object-cover rounded-2xl"
                :alt="conf.title"
              />
              <img v-else src="@/assets/img/logo.png" :alt="conf.title" />
            </router-link>
          </div>
          <div class="text-center mt-5">
            <h3>{{ conf.category }}</h3>
            <router-link :to="'/' + conf.idx">
              <h3 class="font-bold">{{ conf.title }}</h3>
            </router-link>
            <p class="text-lg mt-3 text-gray-800 font-light">
              <span class="font-semibold mr-3 text-black">기간</span>

              {{ formDate2(conf.detailFirstSchedule) }}
              <span v-if="conf.detailLastSchedule">
                ~
                {{ formDate2(conf.detailLastSchedule) }} (회차별 상이)
              </span>
            </p>
            <p class="text-lg text-gray-800 font-light">
              <span class="font-semibold mr-3 text-black">접수</span>
              {{ formDate2(conf.rsdatetime) }}
              ~
              {{ formDate2(conf.redatetime) }}
            </p>
          </div>
        </swiper-slide>
      </swiper-container>
    </div>

    <h3
      v-else
      class="h-[320px] text-white text-center flex justify-center items-center bg-[#0c3388]"
    >
      현재 진행 중인 컨퍼런스가 없습니다.
    </h3>
  </div>

  <div id="pastConf" class="max-xl:max-w-[88vw] xl:max-w-[65vw] mx-auto mb-20">
    <h2 class="nanum-square font-bold text-center mt-20 lg:mt-44 mb-10">
      종료된 컨퍼런스
    </h2>

    <div
      class="grid max-xl:grid-cols-2 xl:grid-cols-4 gap-5"
      v-if="pastConf.length"
    >
      <div v-for="conf in pastConf" :key="conf.idx">
        <div
          class="w-full aspect-[83/50] bg-[#F0F0F0] rounded-xl overflow-hidden"
        >
          <div class="w-full h-full flex justify-center items-center">
            <img
              v-if="conf.thumbnail"
              :src="'https://nodeblack.net/' + conf.thumbnail"
              class="w-full h-full object-cover rounded-2xl"
              :alt="conf.title"
            />
            <img
              v-else
              src="@/assets/img/logo.png"
              :alt="conf.title"
              class="max-w-[75%]"
            />
          </div>
        </div>
        <h6 class="text-center mt-2">{{ conf.category }}</h6>
        <h5 class="">{{ conf.title }}</h5>
      </div>
    </div>

    <!-- <button class="my-20">계속 보기</button> -->
  </div>
</template>
