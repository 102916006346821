<script setup>
import { reactive, onMounted, ref } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";
import router from "@/router";

const { currentRoute } = useRouter();

const state = reactive({
  conference: {},
});

/*axios.get('/api/conferences').then(response => {
  state.conferences = response.data.contents
}).catch(error => {
  console.error(error)
})*/

//접수기간 체크
const isAvailable = (rsdatetime, redatetime) => {
  const today = new Date();
  const startDate = new Date(Date.parse(rsdatetime));
  const endDate = new Date(Date.parse(redatetime));

  // console.log(today)
  // console.log(startDate)
  // console.log(endDate)
  return today >= startDate && today <= endDate;
};

const formatDate = (date) => {
  date = new Date(date);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    hour12: true,
  };
  return date.toLocaleString("ko-KR", options).replace(",", "");
};

const tempFormatDate = (date) => {
  const d = new Date(date);
  const dateOptions = { year: "numeric", month: "long", day: "numeric" };
  const timeOptions = { hour: "numeric", minute: "numeric" };

  // 기본 날짜 포맷
  let formattedDate = d.toLocaleString("ko-KR", dateOptions).replace(",", "");

  // 마감이 23:59 일 경우 '밤 12시' 반환
  if (d.getHours() === 23 && d.getMinutes() === 59) {
    formattedDate += " 밤 12시";
  } else {
    formattedDate += ` ${d.toLocaleString("ko-KR", timeOptions)}`;
  }

  return formattedDate;
};

const isSubVisible = reactive([]);

const toggleItemSubWr = (idx) => {
  isSubVisible[idx] = !isSubVisible[idx];
};

const alertLogin = () => {
  alert("로그인 후 이용해주세요.");
  return;
};

const alertAvailable = () => {
  alert("접수 기간이 아닙니다.");
  return;
};

const alertExceed = () => {
  alert("접수가 마감되었습니다.");
  return;
};

const goApply = (idx) => {
  if (thisPageName === "conferenceListApp") {
    // document.location.href = `/app/register/${idx}`;
    router.push({ path: `/app/register/${idx}` });
  } else {
    // document.location.href = `/register/${idx}`;
    router.push({ path: `/register/${idx}` });
  }
};

const getConferenceList = () => {
  let idx = currentRoute._value.params.idx ?? "9";
  if (location.pathname === "/hana") {
    idx = "2";
  }
  axios
    .get(`/api/conference/${idx}`)
    .then((response) => {
      state.conference = response.data.contents;
      if (idx === "1") {
        state.conference.conferenceDetailList.map((conferenceDetailList) => {
          conferenceDetailList.view = viewCheck(conferenceDetailList.number);
        });
      }

      pageLoadComplete.value = true;
    })
    .catch((error) => {
      console.error(error);
    });
};

// getConferenceList();

const viewCheck = (number) => {
  if (number === 5) {
    //23년 5월 12일 오후 5시 이후부터 보여지도록
    const date = new Date();
    const targetDate = new Date("2023-05-12T17:00:00");
    return date > targetDate;
  } else {
    return true;
  }
};

const thisPageName = currentRoute._value.name;
console.log(thisPageName);

const tokenTest = ref("");

const removeStorageItem = () => {
  if (localStorage.getItem("sns_type")) {
    localStorage.removeItem("sns_type");
  }
  if (localStorage.getItem("sns_id")) {
    localStorage.removeItem("sns_id");
  }
  if (localStorage.getItem("uid")) {
    localStorage.removeItem("uid");
  }
  if (localStorage.getItem("email")) {
    localStorage.removeItem("email");
  }
  if (localStorage.getItem("name")) {
    localStorage.removeItem("name");
  }
  if (localStorage.getItem("phone")) {
    localStorage.removeItem("phone");
  }
};

const pageLoadComplete = ref(false);
onMounted(() => {
  tokenTest.value = localStorage.getItem("authorization");
  getConferenceList();

  // sns 로그인시 사용되는 스토리지 삭제
  removeStorageItem();
});
</script>

<template>
  <div
    class="xl:flex max-xl:max-w-[88vw] xl:max-w-[65vw] mx-auto xl:my-20 my-10 justify-between xl:px-5 gap-10"
  >
    <main style="flex: 1 1 970px" class="max-xl:mb-10">
      <!-- {{ state.conference }} -->
      <div class="mb-4">
        <div class="xl:flex gap-5 items-center">
          <router-link :to="{ name: 'eduList' }" class="block max-xl:w-8 w-10">
            <svg
              class="w-full"
              width="38"
              height="36"
              viewBox="0 0 38 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 2L3 18M3 18L19 34M3 18H38"
                stroke="black"
                stroke-width="3"
              />
            </svg>
          </router-link>
          <div>
            <h3>{{ state.conference.category }}</h3>
            <h1 class="font-bold">{{ state.conference.title }}</h1>
          </div>
        </div>
      </div>

      <div v-html="state.conference.contents"></div>
    </main>
    <aside
      id="edu-list"
      class="w-full mx-auto !justify-start"
      style="flex: 1 1 710px"
    >
      <h3>컨퍼런스 접수</h3>
      <div class="list">
        <template
          v-for="(confList, index) in state.conference.conferenceDetailList"
          :key="confList.idx"
        >
          <div
            class="w-full"
            v-if="
              confList.number !==
                state.conference.conferenceDetailList[index - 1]?.number &&
              confList.view !== false
            "
          >
            <div
              class="item"
              @click="toggleItemSubWr(confList.idx)"
              :id="'conference_' + confList.number"
            >
              <b class="mont" v-if="confList.number === 6">추가</b>
              <b class="mont" v-else>{{ confList.number }}차</b>
              <div>
                <div class="title flex items-baseline">
                  <h5 class="font-medium">
                    {{ confList.local }} {{ confList.place }}
                  </h5>
                  <span class="bar"></span>
                  <h5 class="font-medium">
                    {{ formatDate(confList.schedule) }}
                  </h5>
                </div>

                <!-- <h6
                  v-if="
                    state.conference.idx === 9 || state.conference.idx === 11
                  "
                >
                  ( 등록 마감
                  <b>{{ tempFormatDate(confList.redatetime) }} 밤 12시</b>)
                </h6> -->
                <h6>
                  ( 등록 마감 <b>{{ tempFormatDate(confList.redatetime) }}</b
                  >)
                </h6>
              </div>

              <button>
                <font-awesome-icon
                  :icon="
                    isSubVisible[confList.idx]
                      ? 'fa-solid fa-chevron-up'
                      : 'fa-solid fa-chevron-down'
                  "
                />
              </button>
            </div>
            <div
              class="item_sub_wr flex flex-wrap justify-between py-4"
              :class="{ hidden: isSubVisible[confList.idx] }"
            >
              <div
                class="w-full px-5"
                v-for="filteredConfList in state.conference.conferenceDetailList.filter(
                  (cl) => cl.number === confList.number
                )"
                :key="filteredConfList.idx"
              >
                <div
                  v-if="
                    filteredConfList.idx !== 57 && filteredConfList.idx !== 58
                  "
                  class="item_sub !max-w-full"
                >
                  <div class="flex items-center gap-2">
                    <h5 class="font-medium">{{ filteredConfList.dept }}</h5>
                    <p>
                      <!--                      (인원: <b>{{( filteredConfList.registered_personnel >= filteredConfList.limit_personnel )?"마감":(filteredConfList.registered_personnel === 0)?"무제한":filteredConfList.registered_personnel}}</b>-->
                      <span v-if="state.conference.idx != 11">
                        <!-- (인원:
                        <b>
                          {{
                            filteredConfList.limit_personnel === 0
                              ? "무제한"
                              : filteredConfList.registered_personnel >=
                                filteredConfList.limit_personnel
                              ? "마감"
                              : filteredConfList.registered_personnel
                          }}
                        </b>
                        <span v-if="filteredConfList.limit_personnel > 0">
                          / {{ filteredConfList.limit_personnel }}</span
                        >) -->
                        (잔여석 : <b>{{ filteredConfList.left_personnel }}</b
                        >)
                      </span>
                    </p>
                  </div>
                  <!-- 접수 기간 내에만 노출 -->
                  <div
                    v-if="
                      isAvailable(
                        filteredConfList.rsdatetime,
                        filteredConfList.redatetime
                      )
                    "
                  >
                    <!-- 로그인 유무 -->
                    <a
                      @click="alertLogin()"
                      class="block p-4"
                      :class="{
                        sec_close_btn:
                          filteredConfList.registered_personnel >=
                            filteredConfList.limit_personnel &&
                          filteredConfList.limit_personnel != 0,
                      }"
                      v-if="!$store.state.account.id"
                    >
                      <span
                        v-if="
                          filteredConfList.registered_personnel >=
                            filteredConfList.limit_personnel &&
                          filteredConfList.limit_personnel != 0
                        "
                        >마감</span
                      >
                      <span v-else>신청</span>
                    </a>
                    <!-- 인원 마감 시 -->
                    <a
                      @click="alertExceed()"
                      class="sec_close_btn block p-4"
                      v-else-if="
                        filteredConfList.registered_personnel >=
                          filteredConfList.limit_personnel &&
                        filteredConfList.limit_personnel != 0
                      "
                    >
                      마감
                    </a>
                    <!--                  <router-link :to="'/register/'+filteredConfList.idx" class="sec_rose_btn block p-4" v-else>신청</router-link>-->
                    <a
                      href="javascript:void(0)"
                      @click="goApply(filteredConfList.idx)"
                      v-else
                      >신청</a
                    >
                  </div>
                  <a
                    @click="alertAvailable()"
                    class="sec_close_btn block p-4"
                    v-else
                    >접수전</a
                  >
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </aside>
  </div>
</template>

<style scoped>
.sec_close_btn {
  background-color: #f2f2f2 !important;
  color: #999 !important;
  cursor: not-allowed !important;
}
</style>
