<template>
  <div>
    <TNavComponent v-if="pageName !== 'signup_app'" />
    <appNavigator v-if="pageName === 'signup_app'" :page="pageName" />
    <SignupPage />
    <conferenceFooter />
  </div>
</template>

<script>
import SignupPage from "@/pages/SignupPage.vue";
import TNavComponent from "@/components/new/TNavComponent.vue";
import conferenceFooter from "@/pages/new/conferenceFooter.vue";
import AppNavigator from "@/components/appNavigator.vue";

export default {
  name: "SignupView",
  components: {
    AppNavigator,
    SignupPage,
    TNavComponent,
    conferenceFooter,
  },
  data() {
    return {
      pageName: "",
    };
  },
  mounted() {
    this.pageName = this.$route.name;
  },
};
</script>
