<script setup></script>

<template>
  <div class="w-full border-t border-neutral-300">
    <div
      class="max-xl:max-w-[88vw] xl:max-w-[65vw] flex justify-between items-end mx-auto xl:py-10 py-8"
    >
      <div class="max-xl:flex-col flex gap-10 text-slate-500">
        <div>
          <b>총회교육개발원 컨퍼런스</b>
          <p class="text-sm my-4">
            문의 : 02)559-5643, eduwind.org@gmail.com <br />
            상호 : (재)대한예수교장로회총회
          </p>
          <p class="text-sm">
            Copyright © http://총회교육.com. All rights reserved.
          </p>
        </div>

        <div class="flex h-fit divide-x divide-slate-500 leading-3">
          <router-link to="/policy/info" class="hover:underline px-3"
            >개인정보취급방침</router-link
          >
          <router-link to="/policy/guide" class="hover:underline px-3"
            >이용약관</router-link
          >
          <a
            href="https://xn--9d0bp30cjhe9zk.com/main/bbs/board.php?bo_table=total_qa"
            target="_blank"
            class="hover:underline px-3"
            >문의하기</a
          >
        </div>
      </div>
      <img
        src="@/assets/img/logo.png"
        alt="총회교육개발원 컨퍼런스"
        width="230px"
        class="max-xl:hidden"
      />
    </div>
  </div>
</template>
