<template>
  <div class="top_nav_container bg-white sticky top-0 z-10">
    <div
      class="max-xl:max-w-[88vw] xl:max-w-[65vw] mx-auto lg:grid flex items-center justify-between gap-5 top_nav_menu_wrapper"
    >
      <router-link :to="{ name: 'eduList' }" class="w-fit inline-block py-1">
        <img
          src="@/assets/img/logo.png"
          alt="총회교육개발원 컨퍼런스"
          width="230px"
        />
      </router-link>

      <div class="flex justify-evenly items-center max-lg:hidden gap-5 py-1">
        <div class="top_nav_cates whitespace-pre">
          <a @click="scrollToSection('nowConf')" class="nanum-square">
            진행 중 컨퍼런스
          </a>
        </div>

        <div class="top_nav_cates whitespace-pre">
          <a @click="scrollToSection('pastConf')" class="nanum-square">
            종료된 컨퍼런스
          </a>
        </div>

        <div class="top_nav_cates whitespace-pre">
          <a
            href="https://eduwindmall.com/shop/eduwind/"
            target="_blank"
            class="nanum-square"
            >성경학교 용품·데코</a
          >
        </div>

        <div class="top_nav_cates whitespace-pre">
          <a
            href="https://www.holyonebook.com/main/main.aspx"
            target="_blank"
            class="nanum-square"
            >교재 구입</a
          >
        </div>
      </div>

      <aside class="flex justify-end gap-5 items-center max-lg:gap-1 py-1">
        <!-- 마이페이지 -->
        <VDialog
          v-if="!$store.state.uid.id"
          no-scroll
          :classes="{ bg: 'bg-black-alpha' }"
        >
          <template #toggle="{ bind, on }">
            <button v-bind="bind" v-on="on" class="flex flex-col">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <path
                  d="M20 9C21.4587 9 22.8576 9.57946 23.8891 10.6109C24.9205 11.6424 25.5 13.0413 25.5 14.5C25.5 15.9587 24.9205 17.3576 23.8891 18.3891C22.8576 19.4205 21.4587 20 20 20C18.5413 20 17.1424 19.4205 16.1109 18.3891C15.0795 17.3576 14.5 15.9587 14.5 14.5C14.5 13.0413 15.0795 11.6424 16.1109 10.6109C17.1424 9.57946 18.5413 9 20 9ZM20 22.75C26.0775 22.75 31 25.2113 31 28.25V31H9V28.25C9 25.2113 13.9225 22.75 20 22.75Z"
                  fill="#1B1D21"
                />
              </svg>
            </button>
          </template>

          <template #default="{ close }">
            <div
              class="bg-black bg-opacity-25 w-screen h-screen flex justify-center items-center"
            >
              <div
                class="min-w-[25vw] max-xl:min-w-[70vw] max-md:min-w-[88vw] flex flex-col bg-white w-fit rounded-3xl"
              >
                <button
                  aria-label="close"
                  class="my-dialog__close w-fit mt-3 mr-4 self-end"
                  @click="close"
                >
                  <h4 class="text-2xl">
                    <font-awesome-icon icon="fa-solid fa-circle-xmark" />
                  </h4>
                </button>
                <div
                  class="login max-lg:border-b border-neutral-300"
                  :class="{ 'bg-sky-50': isMenuVisible }"
                >
                  <div class="login_inputs max-w-[658px] mx-auto py-5 px-8">
                    <div
                      class="rounded-t-lg overflow-hidden outline outline-1 outline-neutral-300"
                    >
                      <input
                        type="text"
                        class="p-4 w-full"
                        placeholder="아이디를 입력하세요"
                        v-model="state.form.uid"
                      />
                    </div>
                    <div
                      class="mt-[1px] rounded-b-lg overflow-hidden outline outline-1 outline-neutral-300"
                    >
                      <input
                        type="password"
                        class="p-4 w-full"
                        placeholder="비밀번호"
                        v-model="state.form.upw"
                        @keyup.enter="submit()"
                      />
                    </div>

                    <div class="mt-4 mb-5 text-center pb-5">
                      <button
                        class="!rounded w-1/2 border border-main-navy bg-white p-4"
                        @click="submit()"
                      >
                        <h5 class="text-main-navy font-medium">로그인</h5>
                      </button>
                    </div>

                    <div
                      class="flex justify-center items-center gap-2 leading-none md:text-sm"
                    >
                      <router-link to="/help_find" class="hover:underline"
                        >아이디/비밀번호 찾기</router-link
                      >
                      <span class="block bg-black w-[1px] h-[0.8em]"></span>
                      <router-link to="/signup" class="hover:underline"
                        >회원가입</router-link
                      >
                    </div>

                    <!-- 간편 로그인 -->
                    <div>
                      <p class="lg:mt-6 mt-4 text-center font-medium">
                        간편 로그인 및 회원가입
                      </p>
                      <div class="flex justify-center pt-4 gap-4">
                        <naverLoginComponent />
                        <kakaoLoginComponent />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </VDialog>

        <div class="!w-fit top_nav_cates max-lg:hidden" v-else>
          <router-link to="/">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
            >
              <path
                d="M20 9C21.4587 9 22.8576 9.57946 23.8891 10.6109C24.9205 11.6424 25.5 13.0413 25.5 14.5C25.5 15.9587 24.9205 17.3576 23.8891 18.3891C22.8576 19.4205 21.4587 20 20 20C18.5413 20 17.1424 19.4205 16.1109 18.3891C15.0795 17.3576 14.5 15.9587 14.5 14.5C14.5 13.0413 15.0795 11.6424 16.1109 10.6109C17.1424 9.57946 18.5413 9 20 9ZM20 22.75C26.0775 22.75 31 25.2113 31 28.25V31H9V28.25C9 25.2113 13.9225 22.75 20 22.75Z"
                fill="#1B1D21"
              />
            </svg>
          </router-link>
          <div>
            <div
              v-if="$store.state.level.id === 10"
              class="flex flex-col justify-center items-center"
            >
              <router-link to="/profile/confList" class="text-center w-full"
                ><h6>신청내역</h6></router-link
              >
              <span class="block bg-white w-[1px]"></span>
              <router-link to="/profile" class="text-center w-full"
                ><h6>회원정보</h6></router-link
              >
              <span class="block bg-white w-[1px]"></span>
              <router-link to="/management" class="text-center w-full"
                ><h6>관리자</h6></router-link
              >
            </div>

            <div v-else class="flex flex-col justify-center items-center">
              <router-link to="/profile/confList" class="text-center w-full"
                ><h6>신청내역</h6></router-link
              >
              <span class="block bg-white w-[1px]"></span>
              <router-link to="/profile" class="text-center w-full"
                ><h6>회원정보</h6></router-link
              >
            </div>

            <button
              type="button"
              @click="logout()"
              class="text-neutral-700 hover:text-neutral-500 mb-3"
            >
              로그아웃
              <font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" />
            </button>
          </div>
        </div>

        <!-- 메뉴 -->
        <button
          @click="toggleMenu()"
          type="button"
          class="lg:hidden max-md:w-8 max-md:h-8"
        >
          <svg
            class="w-full h-full"
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
          >
            <path
              d="M5 30V26.6667H35V30H5ZM5 21.6667V18.3333H35V21.6667H5ZM5 13.3333V10H35V13.3333H5Z"
              fill="#1B1D21"
            />
          </svg>
        </button>
      </aside>
    </div>
  </div>

  <div
    v-if="isMenuVisible"
    class="fixed w-[80vw] top-0 right-0 h-full bg-white border-l border-neutral-200 p-7 z-50 md:w-[24.3%] md:min-w-[28.125rem] md:p-[2vw]"
  >
    <div class="grid gap-5">
      <div class="flex justify-between items-center">
        <button
          v-if="$store.state.uid.id"
          type="button"
          @click="logout()"
          class="text-neutral-500"
        >
          로그아웃
          <font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" />
        </button>

        <button type="button" @click="toggleMenu()" class="ml-auto mr-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path d="M1 1L21 21" stroke="black" stroke-width="1.5" />
            <path d="M21 1L1 21" stroke="black" stroke-width="1.5" />
          </svg>
        </button>
      </div>

      <div v-if="$store.state.uid.id" class="flex flex-col">
        <div
          class="flex justify-center items-center border border-main-navy divide-x divide-main-navy mb-4"
        >
          <router-link
            to="/profile/confList"
            class="text-center w-full p-3 text-main-navy text-sm"
            ><h6>신청내역</h6></router-link
          >
          <router-link
            to="/profile"
            class="text-center w-full p-3 text-main-navy text-sm"
            ><h6>회원정보</h6></router-link
          >
        </div>
      </div>

      <div class="whitespace-pre">
        <router-link to="/" class="nanum-square text-lg"
          >컨퍼런스 목록</router-link
        >
      </div>

      <div class="whitespace-pre">
        <a
          href="https://eduwindmall.com/shop/eduwind/"
          target="_blank"
          class="nanum-square text-lg"
          >성경학교 용품·데코</a
        >
      </div>

      <div class="whitespace-pre">
        <a
          href="https://www.holyonebook.com/main/main.aspx"
          target="_blank"
          class="nanum-square text-lg"
          >교재 구입</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, watch, onMounted } from "vue";
import axios from "axios";
import { onBeforeRouteLeave } from "vue-router";
import { VDialog } from "vuetensils/src/components";

import store from "@/store";
import router from "@/router";
import { useRouter } from "vue-router";

import naverLoginComponent from "@/components/naverLoginComponent.vue";
import kakaoLoginComponent from "@/components/kakaoLoginComponent.vue";

import { useRoute } from "vue-router";

export default {
  name: "TNavComponent",

  components: {
    VDialog,
    naverLoginComponent,
    kakaoLoginComponent,
  },

  setup() {
    const route = useRoute();
    const state = reactive({
      form: {
        uid: "",
        upw: "",
        sns_type: "",
      },
      conference_idx: 0,
    });

    //메뉴 토글
    const isMenuVisible = ref(false);

    const toggleMenu = () => {
      isMenuVisible.value = !isMenuVisible.value;
      // document.body.classList.toggle('fixed', isMenuVisible.value)
    };
    onBeforeRouteLeave(() => {
      //페이지 이동시 isMenuVisible, body fixed 초기화
      isMenuVisible.value = false;
      // document.body.classList.remove('fixed');
    });

    //로그인,아웃
    const submit = () => {
      if (!state.form.uid) {
        alert("아이디를 입력하세요.");
        return;
      } else if (!state.form.upw) {
        alert("비밀번호를 입력하세요.");
        return;
      } else {
        axios
          .post("/api/account/login", state.form)
          .then((res) => {
            console.log(res.data);
            if (res.data.err_code == 0) {
              //로그인 성공일 경우
              // store.commit('setAccount', state.form.uid)
              localStorage.setItem(
                "authorization",
                res.data.contents.body.accessToken
              );
              router.go({ path: "/" });
            } else {
              alert(res.data.err_msg);
            }
          })
          .catch(() => {
            alert("아이디 또는 비밀번호를 확인하세요.");
          });
      }
    };

    const logout = () => {
      localStorage.removeItem("authorization");
      store.commit("setAccount", 0);
      store.commit("setLevel", 0);
      store.commit("setUid", "");
      router.push({ path: "/" });
    };

    return {
      state,
      isMenuVisible,
      toggleMenu,
      submit,
      logout,
    };
  },

  methods: {
    alert_msg(v) {
      if (v == 1) {
        alert("5월2일 화요일 12시에 오픈됩니다!");
      } else if (v == 2) {
        alert("서비스 점검 중 입니다. 오후에 다시 접수 부탁 드리겠습니다.");
        return false;
      }
    },

    scrollToSection(sectionId) {
      this.$router.push("/").then(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      });
    },
  },
};
</script>
