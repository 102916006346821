<template>
  <div>
    <TNavComponent />
    <ConferenceDetailPage />
    <conferenceFooter />
  </div>
</template>

<script>
import TNavComponent from "@/components/new/TNavComponent.vue";
import ConferenceDetailPage from "@/pages/conferenceDetailPage.vue";
import conferenceFooter from "@/pages/new/conferenceFooter.vue";

export default {
  name: "conferenceView",
  components: { TNavComponent, ConferenceDetailPage, conferenceFooter },
};
</script>

<style scoped></style>
