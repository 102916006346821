<script setup>
const openWebBrowser = () => {
  /*var data = {
    message: "openWebBrowser",
    additionalData: {
      url: "https://eduwindmall.com/shop/eduwind/index.php"
    }
  };
  window.dataChannel.postMessage(data);*/
  getData.postMessage('openWebBrowser');
};
</script>

<template>

  <main class="min-h-screen">
    <div class="max-w-[76.25rem] flex flex-col items-center text-center mx-auto">
      <img src="@/assets/img/2025_app_hana.jpg" alt="하나바이블 컨퍼런스 안내"/>

      <div class="w-full flex flex-col gap-2 p-4 mb-10">
        <!-- <a href="https://eduwindmall.com/shop/eduwind/goods/list.php" target="_blank" class="flex items-center !rounded-full"> -->
        <a @click="openWebBrowser" class="flex items-center !rounded-full">
          <div>
            하나바이블 용품 구입
          </div>
          <div class="w-fit">
            <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.4337 5.58333L0.333339 5.58333L0.33334 4.41666L10.4337 4.41666L7.30467 1.28766L8.12951 0.462829L12.6667 5L8.1295 9.53716L7.30467 8.71233L10.4337 5.58333Z" fill="white"/>
            </svg>
          </div>
        </a>
        <!-- <a href="https://www.holyonebook.com/main/main.aspx" target="_blank" class="flex items-center !rounded-full">
          <div>
            교재 구입
          </div>
          <div class="w-fit">
            <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.4337 5.58333L0.333339 5.58333L0.33334 4.41666L10.4337 4.41666L7.30467 1.28766L8.12951 0.462829L12.6667 5L8.1295 9.53716L7.30467 8.71233L10.4337 5.58333Z" fill="white"/>
            </svg>
          </div>
        </a> -->


      </div>
    </div>

  </main>
</template>

<style scoped>
 main::before {
  content: '';
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  background: #fff;
  z-index: -1;
 }

 a {
  display: grid;
  grid: 1fr / 10rem auto;
  justify-content: center;
  background: #080e44;
  color: #fff;
  border-radius: 4px;
  width: 100%;
  padding: 1rem;
  gap: 0.5rem;
 }
</style>