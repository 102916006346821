import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/style.css";
import axios from "axios";

// import the fontawesome core
import { library } from "/node_modules/@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "/node_modules/@fortawesome/vue-fontawesome";

import { faCalendar } from "/node_modules/@fortawesome/free-solid-svg-icons";
import { faCommentDots } from "/node_modules/@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "/node_modules/@fortawesome/free-solid-svg-icons";
import { faMagnifyingGlass } from "/node_modules/@fortawesome/free-solid-svg-icons";
import {
  faCirclePlus,
  faCircleXmark,
} from "/node_modules/@fortawesome/free-solid-svg-icons";
import {
  faPlus,
  faXmark,
  faReceipt,
} from "/node_modules/@fortawesome/free-solid-svg-icons";
import {
  faChevronUp,
  faChevronDown,
} from "/node_modules/@fortawesome/free-solid-svg-icons";
import {
  faArrowRightFromBracket,
  faBars,
  faPenToSquare,
} from "/node_modules/@fortawesome/free-solid-svg-icons";

import { register } from "swiper/element/bundle";
register();

// VCalendar
import VCalendar from "v-calendar";
import "v-calendar/style.css";

// VPagination
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import { createPinia } from "pinia";
const pinia = createPinia();

axios.defaults.timeout = 100000;
axios.defaults.baseURL = window.location.protocol + "//" + window.location.host;
axios.defaults.port = ":" + window.location.port;
// 캐싱 방지
axios.defaults.headers.get["Cache-Control"] = "no-cache";
axios.defaults.headers.get["Pragma"] = "no-cache";

axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=utf-8";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Access-Control-Allow-Headers"] = "*";
axios.defaults.headers.common["Access-Control-Allow-Credentials"] = true;

axios.interceptors.request.use((request) => {
  // if (request.port != ":3000") {
  if (request.url.indexOf("/api/") > -1) {
    //spring-boot api 호출의 경우
    request.baseURL = window.location.protocol + "//nodeblack.net";
    // request.baseURL = "http://localhost:8080";
    //request.url = request.url.replace("/api","");
  }
  if (request.url.indexOf("/new_api/") > -1) {
    // request.baseURL = "http://118.67.134.110:18080/";
    request.baseURL = "https://eduwind-api.nodeblack.net/";
    request.url = request.url.replace("/new_api/", "/api/");
  }
  if (request.url.indexOf("/oauth2.0") > -1) {
    request.baseURL = "https://nid.naver.com";
  }
  if (request.url === "/v1/nid/me") {
    request.baseURL = "https://openapi.naver.com";
  }
  if (request.url === "/v2/sender/send") {
    request.baseURL = "https://alimtalk-api.bizmsg.kr";
  }
  // }
  if (request.url === "/v2/sender/send") {
    request.baseURL = "https://alimtalk-api.bizmsg.kr";
  }

  // console.log(request.url);
  if (
    request.url !== "/v1/nid/me" &&
    request.url !== "/api/account/lost/confirm"
  ) {
    const token = localStorage.getItem("authorization");
    if (token) {
      // alert(`header : ${request.url} : ${token}`);
      request.headers.authorization = token;
      // if( request.url === '/api/account/check' ){
      //   alert(`header : ${token}`);
      // }
      /*
        if( request.url === '/profile/confList/app' ){
          request.headers.Authorization = `Bearer ${token}`;
        }
        else {
          request.headers.authorization = token;
        }
        */
      /* /profile/confList/app 의경우 웹뷰 호출시 APP에서 authorization값을 넣어줌 */
      /*if( window.location.pathname !== '/profile/confList/app' ){
            request.headers.authorization = token;
        }*/
    }
    // request.headers.authorization = localStorage.getItem('authorization');
  }

  /*if( request.url === '/v2/sender/send' ){
        request.baseURL = "https://alimtalk-api.bizmsg.kr";
    }*/

  //console.log(request.baseURL);
  //console.log(request.headers);

  return request;
});

router.beforeEach((to, from, next) => {
  // 스크롤 위치 초기화
  window.scrollTo(0, 0);
  next();
});

/* add icons to the library */
library.add(
  faCalendar,
  faCircleCheck,
  faCommentDots,
  faMagnifyingGlass,
  faCirclePlus,
  faCircleXmark,
  faReceipt
);
library.add(
  faPlus,
  faXmark,
  faChevronUp,
  faChevronDown,
  faArrowRightFromBracket,
  faBars,
  faPenToSquare
);

createApp(App)
  .use(store)
  .use(pinia)
  .use(router)
  .use(VCalendar, {})
  .use(VPagination)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
