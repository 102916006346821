<template>
  <div>
    <TNavComponent />
    <conferencePage />
    <conferenceFooter />
  </div>
</template>

<script>
import TNavComponent from "@/components/new/TNavComponent.vue";
import conferencePage from "@/pages/new/conferencePage.vue";
import conferenceFooter from "@/pages/new/conferenceFooter.vue";

export default {
  name: "conferenceList",
  components: { conferencePage, TNavComponent, conferenceFooter },

  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        window.location.reload();
      }
    },
  },
};
</script>

<style scoped></style>
