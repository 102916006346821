// 일시(date) 변환
export const formDate = (d) => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  const date = new Date(d);
  return date
    .toLocaleDateString("ko-KR", options)
    .replace(/(\d+)\D(\d+)\D(\d+)\D(\d+)\D(\d+)/, "$1.$2.$3 $4:$5:$6");
};

export const formDate2 = (d) => {
  const date = new Date(d);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}.${month}.${day}`;
};

export const CARD_TYPE = (cardCode) => {
  const cardNames = {
    "01": "하나카드",
    "02": "우리카드",
    "03": "롯데카드",
    "04": "현대카드",
    "06": "국민카드",
    11: "비씨카드",
    12: "삼성카드",
    14: "신한카드(구.LG카드 포함)",
    21: "글로벌 VISA",
    22: "글로벌 MASTER",
    23: "글로벌 JCB",
    24: "글로벌 아멕스",
    25: "글로벌 다이너스",
    26: "중국은련카드",
    32: "광주카드",
    33: "전북카드",
    34: "하나SK카드",
    35: "산업카드",
    38: "우리BC카드 (44 사용 시 변경필요)",
    41: "NH카드",
    43: "씨티카드",
    48: "신협체크카드",
    51: "수협카드",
    52: "제주카드",
    54: "MG새마을금고체크",
    55: "케이뱅크카드",
    56: "카카오뱅크",
    71: "우체국체크",
    95: "저축은행체크",
  };

  return cardNames[cardCode] || "카드사를 찾을 수 없습니다.";
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatOrderStatus = (orderStatus) => {
  switch (orderStatus) {
    case "PAY_WAIT":
      return "결제대기";
    case "VBANK_WAIT":
      return "입금대기";
    case "PAY_FAIL":
      return "결제실패";
    case "ORDER_DONE":
      return "결제완료";
    case "REFUND_WAIT":
      return "환불 대기 및 진행 중";
    case "EXTRA_PAID":
      return "추가 결제 완료";
    case "PART_RETURNED":
      return "부분 환불";
    case "FULL_RETURNED":
      return "전체 환불";
    case "PART_EXCHANGED":
      return "부분 교환";
    case "FULL_EXCHANGED":
      return "전체 교환";
    case "CANCEL":
      return "단순 취소";
    case "EXTRA_CREATED":
      return "(추가)주문생성";
    case "EXTRA_TRY":
      return "(추가)주문시도";
    case "EXTRA_FAIL":
      return "(추가)주문실패";
    case "EXTRA_VBANK":
      return "(추가)입금대기";
    case "EXTRA_DONE":
      return "(추가)결제완료";
    case "DELETED":
      return "(추가)주문삭제";
    default:
      return "-";
  }
};

export const status = {
  // 상품 주문 상태
  getProductStatus(status) {
    switch (status) {
      case "NOT_PAID":
        return "결제 전";
      case "READY":
        return "배송 준비";
      case "START":
        return "배송 중";
      case "DELIVERED":
        return "배송 완료";
      case "PART_RETURNED":
        return "부분 반품 완료";
      case "FULL_RETURNED":
        return "전체 반품 완료";
      case "RAPT_EXCHANGED":
        return "부분 교환 완료";
      case "FULL_EXCHANGED":
        return "전체 교환 완료";
      default:
        return "-";
    }
  },

  // 결제 수단 정리
  formatPayMethod(payMethod) {
    switch (payMethod) {
      case "Card":
        return "카드";
      case "Directbank":
        return "실시간계좌이체";
      case "vbank":
        return "가상계좌입금";
      case "free":
        return "무료";
      default:
        return "-";
    }
  },
};

export const inicisDateFormat = (date, time) => {
  const year = date.substring(0, 4);
  const month = date.substring(4, 6);
  const day = date.substring(6, 8);
  const hour = time.substring(0, 2);
  const minute = time.substring(2, 4);
  const second = time.substring(4, 6);

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
};

export const returnTypeTxt = (type) => {
  switch (type) {
    case "MIND_CHANGE":
      return "단순 변심";
    case "MISTAKE":
      return "주문 실수";
    case "DAMAGE":
      return "파손 및 불량";
    case "MISDELIVERY":
      return "오배송 및 지연";
    case "OPTION_CHANGE":
      return "옵션 변경";
    default:
      return "-";
  }
};

export const paymentRefundTypeTxt = (type) => {
  switch (type) {
    case "EXCHANGE":
      return "교환";
    case "PAYBACK":
      return "반품";
    case "CANCEL":
      return "취소";
    default:
      return "-";
  }
};

export const paymentRefundStatusTxt = (status) => {
  switch (status) {
    case "REFUND_REQUESTED":
      return "요청";
    case "REFUND_REQUEST_CANCEL":
      return "요청 취소";
    case "REFUND_DENIED":
      return "요청 거절";
    case "REFUND_APPROVED":
      return "요청 승인";
    case "REFUND_PAY_TRY":
      return "결제 시도";
    case "REFUND_PAY_FAIL":
      return "결제 실패";
    case "REFUND_VBANK":
      return "입금 대기";
    case "REFUND_PAID":
      return "추가 결제 완료";
    case "REFUND_DONE":
      return "반품 완료";
    case "REFUND_EXCHANGE":
      return "교환 완료";
    case "REFUND_CANCEL":
      return "단순 주문 취소";
    default:
      return "-";
  }
};
