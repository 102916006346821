import { createRouter, createWebHistory } from "vue-router";
import axios from "axios";

import HomeView from "../views/HomeView.vue";
import LoginView from "@/views/LoginView.vue";
import SignupView from "@/views/SignupView.vue";
import EduManagementView from "@/views/EduManagementView.vue";
import EduListView from "@/views/EduListView.vue";
import EduRegisterView from "@/views/EduRegisterView.vue";
import EduRegisterConfirmView from "@/views/EduRegisterConfirmView.vue";
import MemberProfileView from "@/views/MemberProfileView.vue";
import EduRegisterDone from "@/views/EduRegisterDoneView.vue";
import PolicyView from "@/views/PolicyView.vue";
import naverLoginCheck from "@/components/naverLoginCheckComponent.vue";
import appleLoginCheck from "@/components/appleLoginCheckComponent.vue";
import alimtalkComponent from "@/components/alimtalkComponent.vue";
import logoutView from "@/views/logoutView.vue";
import testComponent from "@/components/test.vue";
import myCancelList from "@/views/cancelListView.vue";
import RegisteredMembersPage2 from "@/pages/management/RegisteredMembersPage2.vue"; // 컨퍼런스 등록 회원 목록2
import QnaListPage from "@/pages/management/QnaListPage.vue";

import qnaView from "@/views/qnaView.vue";
import barcodeView from "@/views/BarcodeView.vue";
import barcodeVerifyView from "@/views/barcodeVerifyView.vue";
import store from "@/store";
import conferenceListView from "@/views/conferenceListView.vue";
import NewconferenceListView from "@/views/new/conferenceListView.vue";
import conferenceDetailView from "@/views/conferenceDetailView.vue";

import onlineMeeting from "@/views/zoomLinkView.vue";

import contentsMainView from "@/views/contentsMainView.vue";
import contentsDetailView from "@/views/contentsDetailView.vue";

import myPageView from "@/views/myPageView.vue";
import myMainPage from "@/pages/myPage/myMainPage.vue";
import myPageDetailView from "@/views/myPageDetailView.vue";
import myListPage from "@/pages/myPage/myListPage.vue";
import myListDetailPage from "@/pages/myPage/myListDetailPage.vue";
import myListDetailPage2 from "@/pages/myPage/myListDetailPage2.vue";
import postPage from "@/pages/myPage/myPostPage.vue";

import myCartPage from "@/pages/myPage/myCartPage.vue";
import myCartPage2 from "@/pages/myPage/myCartPage2.vue";
import myOrderPage from "@/pages/myPage/myOrderPage.vue";
import myOrderPage2 from "@/pages/myPage/myOrderPage2.vue";
import myOrderPage3 from "@/pages/myPage/myOrderPage3.vue";
import myOrderDonePage from "@/pages/myPage/myOrderDonePage.vue";
import myContentsPage from "@/pages/myPage/myContentsPage.vue";
import myContentsDetailPage from "@/pages/myPage/myContentsDetailPage.vue";
import downloadHistory from "@/pages/myPage/downloadHistory.vue";
import reviewPage from "@/pages/myPage/myReviewPage.vue";

import introView from "@/views/introView.vue";

import communityView from "@/views/communityView.vue";
import communityListPage from "@/pages/community/communityListPage.vue";
import communityWritePage from "@/pages/community/communityWritePage.vue";
import communityViewPage from "@/pages/community/communityViewPage.vue";

import helpView from "@/views/helpView.vue";
import appMain from "@/pages/AppMainPage.vue";
import appHana1 from "@/pages/AppHana1Page.vue";
import appHana2 from "@/pages/AppHana2Page.vue";
import { components } from "vuetensils";

const routes = [
  { path: "/home", name: "home", component: HomeView },
  // { path: "/", name: "eduList", component: conferenceListView },
  // { path: "/:idx", name: "conferenceList", component: conferenceListView },
  { path: "/", name: "eduList", component: NewconferenceListView },
  { path: "/:idx", name: "conferenceList", component: conferenceDetailView },

  {
    path: "/app/:idx",
    name: "conferenceListApp",
    component: conferenceListView,
  },
  { path: "/hana", name: "hanaeduList", component: conferenceListView },
  { path: "/login", name: "login", component: LoginView },
  { path: "/help_find_2", name: "helpPage", component: helpView },
  { path: "/help_find", name: "helpPage", component: helpView },
  { path: "/app/help_find", name: "helpPageApp", component: helpView },
  // { path: '/help/pw', name: 'helpPw', component: import('@/views/helpView.vue') },
  { path: "/signup", name: "signup", component: SignupView },
  { path: "/signup/app", name: "signup_app", component: SignupView },
  { path: "/signup_sns", name: "signup_sns", component: SignupView },
  {
    path: "/management",
    name: "eduManagement",
    component: EduManagementView,
    meta: { requiresAuth: true },
  },
  {
    path: "/management/:token",
    name: "eduManagementToken",
    component: EduManagementView,
    meta: { requiresAuth: true },
  },
  {
    path: "/register",
    name: "eduRegister",
    component: EduRegisterView,
    props: true,
  },
  {
    path: "/register/:idx",
    name: "eduRegister2",
    component: EduRegisterView,
    props: true,
  },
  {
    path: "/register_pay/:idx",
    name: "eduRegisterConfirm",
    component: EduRegisterConfirmView,
    props: true,
  },
  {
    path: "/register_done",
    name: "eduRegisterDone",
    component: EduRegisterDone,
  },

  {
    path: "/app/register",
    name: "eduRegisterApp",
    component: EduRegisterView,
    props: true,
  },
  {
    path: "/app/register/:idx",
    name: "eduRegister2App",
    component: EduRegisterView,
    props: true,
  },
  {
    path: "/app/register_pay/:idx",
    name: "eduRegisterConfirmApp",
    component: EduRegisterConfirmView,
    props: true,
  },
  {
    path: "/app/register_done",
    name: "eduRegisterDoneApp",
    component: EduRegisterDone,
  },

  { path: "/policy/info", name: "PolicyInfo", component: PolicyView },

  { path: "/test", name: "test", component: testComponent },

  // 회원 탈퇴
  {
    path: "/withdrawal",
    name: "withdrawal",
    component: () => import("@/views/withdrawalView.vue"),
  },
  {
    path: "/app/withdrawal",
    name: "withdrawalApp",
    component: () => import("@/views/withdrawalView.vue"),
  },

  // naver login callback url
  {
    path: "/naverLoginCheck",
    name: "naverLoginCheck",
    component: naverLoginCheck,
  },
  {
    path: "/appleLoginCheck",
    name: "appleLoginCheck",
    component: appleLoginCheck,
  },

  {
    path: "/alimtalk_test",
    name: "alimtalk_test",
    component: alimtalkComponent,
  },
  { path: "/myCancelList", name: "my_cancel_list", component: myCancelList }, // 취소 신청 내역
  {
    path: "/management2",
    name: "eduManagement2",
    component: RegisteredMembersPage2,
  }, // 컨퍼런스 등록 회원 목록2
  { path: "/qnalist", name: "qnalist", component: QnaListPage },

  {
    path: "/policy/info",
    name: "PolicyInfo",
    component: PolicyView,
    props: { currentComponent: "PolicyInfoPage" },
  },
  {
    path: "/policy/guide",
    name: "PolicyGuide",
    component: PolicyView,
    props: { currentComponent: "PolicyGuidePage" },
  },

  {
    path: "/profile",
    name: "memberProfile",
    component: MemberProfileView,
    props: { currentComponent: "MemberProfilePage" },
    meta: { requiresAuth: true },
  },
  {
    // 웹에서 사용 중
    path: "/profile/confList",
    name: "memberProfileConference",
    component: MemberProfileView,
    props: { currentComponent: "MemberProfileConfPage" },
    meta: { requiresAuth: true },
  },
  {
    // 앱 에서 '컨퍼런스' 탭에서 사용 중
    path: "/profile/confList/app",
    name: "memberProfileConferenceApp",
    component: MemberProfileView,
    props: { currentComponent: "MemberProfileConfPage" },
    meta: { requiresAuth: true },
  },

  {
    // 앱 에서 '내정보' 탭에서 사용 중
    path: "/profile/confList/app2",
    name: "memberProfileConferenceApp2",
    component: MemberProfileView,
    props: { currentComponent: "MemberProfileConfPage" },
    meta: { requiresAuth: true },
  },

  {
    path: "/profile/confList/test",
    name: "memberProfileConferenceTest",
    component: MemberProfileView,
    props: { currentComponent: "MemberProfileConfPageTest" },
  },

  { path: "/logout", name: "logout", component: logoutView }, // 로그아웃
  { path: "/qna", name: "qna", component: qnaView },

  { path: "/barcode/:idx", name: "barcode", component: barcodeView },
  {
    path: "/barcodeVerify",
    name: "barcodeVerify",
    component: barcodeVerifyView,
  },

  { path: "/online/:idx", name: "onlineMeeting", component: onlineMeeting },

  // 콘텐츠몰
  { path: "/mall", name: "contentsMain", component: contentsMainView },
  {
    path: "/mall/:category",
    name: "contentsCategory",
    component: contentsMainView,
  },
  {
    // path: '/mall/view/:contentId',
    path: "/mall/view/:itemId",
    name: "contentsDetail",
    component: contentsDetailView,
  },
  // { path: '/mall/view/:itemId', name: 'itemDetail', component: contentsDetailView },

  // 마이페이지
  {
    path: "/mypage",
    name: "mypage",
    component: myPageView,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: "myMain",
        component: myMainPage,
      },
      {
        path: "list",
        name: "myList",
        component: myListPage,
        meta: { pageType: "purchase" },
      },
      {
        path: "paymentList",
        name: "myPayment",
        component: myListPage,
        meta: { pageType: "payment" },
      },
      {
        path: "list/:id",
        name: "myListDetail",
        component: myListDetailPage,
      },
      {
        path: "detail/:id",
        name: "myOrderDetail",
        component: myListDetailPage2,
      },
      {
        path: "cart",
        name: "cart",
        component: myCartPage2,
        // component: myCartPage,
      },

      {
        // 카티고리 2
        path: "cart2",
        name: "cart2",
        component: myCartPage2,
      },
      // 일반적 결제
      {
        path: "order",
        name: "order",
        component: myOrderPage3,
        // component: myOrderPage2
      },
      // 일반적 결제
      {
        path: "order2",
        name: "order2",
        component: myOrderPage3,
      },
      {
        path: "order/:paymentStatus",
        name: "orderPaymentStatus",
        component: myOrderPage2,
      },
      // 테스트 1. 아이프레임 안 레이어
      /*{
        path: 'order2',
        name: 'order2',
        component: myOrderPage2
      },*/

      {
        path: "order_done/:oid",
        name: "orderDone",
        component: myOrderDonePage,
      },
      {
        path: "contentsBox",
        name: "contentsBox",
        component: myContentsPage,
      },
      {
        path: "contentsBox/:ocid",
        name: "contentsBoxDetail",
        component: myContentsDetailPage,
      },
      {
        path: "downloadHistory",
        name: "downloadHistory",
        component: downloadHistory,
      },
      {
        path: "review",
        name: "review",
        component: reviewPage,
      },
      {
        path: "post",
        name: "post",
        component: postPage,
      },
    ],
  },

  // 소개
  { path: "/introduce/:category", name: "intros", component: introView },

  // 커뮤니티
  {
    path: "/community",
    name: "community",
    redirect: "/community/1",
    component: communityView,
    children: [
      {
        path: ":boardId",
        name: "communityList",
        component: communityListPage,
      },
      {
        path: "write/:postId?",
        name: "communityWrite",
        component: communityWritePage,
      },
      {
        path: ":boardId/:postId",
        name: "communityView",
        component: communityViewPage,
      },
    ],
  },

  { path: "/app_main", name: "app_main", component: appMain },
  { path: "/app_hana1", name: "app_hana1", component: appHana1 },
  { path: "/app_hana2", name: "app_hana2", component: appHana2 },

  // tokenGateway
  {
    path: "/tokenGateway",
    name: "tokenGateway",
    component: () => import("@/components/tokenGateway.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // 이전 페이지 체크
  // alert(from.fullPath);

  localStorage.setItem("prevPath", from.fullPath);
  localStorage.setItem("prevPathName", from.name);

  // const testToken = localStorage.getItem('authorization');
  // alert(`router token : ${testToken}`);

  // get parameter
  // const query = to.query;
  const queryToken = to.params.token;
  if (queryToken !== undefined) {
    const getToken = atob(queryToken);
    localStorage.setItem("authorization", getToken);
  }

  // 앱 관련 게이트웨이 페이지
  // alert(to.name);
  // alert(from.name);

  // console.log(to);
  // console.log(from);
  /*if( to.name == 'memberProfileConferenceApp' && from.name != 'tokenGateway'){
    next({ name: 'tokenGateway' });
  }*/

  // TODO: 로그인 여부 확인
  // 라우터 가드
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // const testAlert = localStorage.getItem('authorization');
    const { data } = await axios.get("/api/account/check");

    // alert(`data.err_code : ${data.err_code}`);
    // alert(`data.contents : ${data.contents}`);
    if (data.err_code === 0) {
      if (data.contents === null) {
        alert("로그인 후 이용 가능 합니다.");
        //if( to.name == 'memberProfileConferenceApp_test' ){
        if (
          to.name == "memberProfileConferenceApp" ||
          to.name == "memberProfileConferenceApp2"
        ) {
          // TODO: APP 로그인 페이지로 이동
          getData.postMessage("login");
        } else {
          // 직전 페이지로 이동
          const previousRoute = router.currentRoute.value;
          if (previousRoute.name) {
            next({ name: previousRoute.name });
          } else {
            // 이전 경로 정보가 없을 경우, 기본적으로 'eduList'로 이동하도록 설정
            next({ name: "eduList" });
          }
        }
      }
      const idx = data.contents.member_idx;
      store.commit("setAccount", idx || 0);
      localStorage.setItem(
        "authorization",
        data.contents.token.body.accessToken
      );
      const res = getMember(idx);
      if (res) {
        next();
      } else {
        if (
          to.name == "memberProfileConferenceApp" ||
          to.name == "memberProfileConferenceApp2"
        ) {
          // TODO: APP 로그인 페이지로 이동
          getData.postMessage("login");
        } else {
          alert("로그인 후 이용 가능 합니다.");
          next({ name: "eduList" });
        }
      }
    } else if (data.err_code === 401) {
      // 로그아웃 처리
      localStorage.removeItem("authorization");
      store.commit("setAccount", 0);
      store.commit("setLevel", 0);
      store.commit("setUid", "");

      alert("로그인 후 이용 가능 합니다.");
      // alert(data.err_msg);

      if (
        to.name == "memberProfileConferenceApp" ||
        to.name == "memberProfileConferenceApp2"
      ) {
        // TODO: APP 로그인 페이지로 이동
        getData.postMessage("login");
      } else {
        next({ name: "eduList" });
      }
    } else {
      // 로그아웃 처리
      alert("로그인 후 이용 가능 합니다.");
      if (
        to.name == "memberProfileConferenceApp" ||
        to.name == "memberProfileConferenceApp2"
      ) {
        // TODO: APP 로그인 페이지로 이동
        getData.postMessage("login");
      } else {
        next({ name: "eduList" });
      }
    }
    // next();
  } else {
    const authorization = localStorage.getItem("authorization");
    // if (authorization !== null && authorization !== '' && authorization !== 'undefined') {
    if (
      authorization !== null &&
      authorization !== "" &&
      authorization !== "undefined"
    ) {
      const { data } = await axios.get("/api/account/check");
      // alert(data.err_msg);
      if (data.err_code === 0) {
        const idx = data.contents.member_idx;
        // alert(idx);
        store.commit("setAccount", idx || 0);
        // alert(data.contents.token.body.accessToken);
        localStorage.setItem(
          "authorization",
          data.contents.token.body.accessToken
        );
        const res = getMember(idx);
        if (res) {
          next();
        }
        // next();
      } else if (data.err_code === 401) {
        localStorage.removeItem("authorization");
        if (
          to.name == "memberProfileConferenceApp" ||
          to.name == "memberProfileConferenceApp2"
        ) {
          next({ path: "/app_main" });
        } else if (
          to.name == "app_main" ||
          to.name == "signup_app" ||
          to.name == "eduRegisterApp" ||
          to.name == "eduRegister2App" ||
          to.name == "eduRegisterConfirmApp" ||
          to.name == "eduRegisterDoneApp" ||
          to.name == "app_hana1" ||
          to.name == "app_hana2"
        ) {
          next();
        } else {
          // 로그아웃 처리
          store.commit("setAccount", 0);
          store.commit("setLevel", 0);
          store.commit("setUid", "");

          // alert('로그인 후 이용 가능 합니다.');
          next({ name: "eduList" });
        }
      }
    }
    next();
  }
});

const getMember = async (idx) => {
  if (idx) {
    await axios.get(`/api/member/${idx}`).then(({ data }) => {
      if (data.err_code === 0) {
        store.commit("setLevel", data.contents.mlevel);
        store.commit("setUid", data.contents.uid);
        return true;
      } else {
        return false;
      }
    });
  }
};

export default router;
